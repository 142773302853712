// export const baseUrl = "https://bbazar.test/"
// export const baseUrl = "http://192.168.0.117/bbazar/public/"
export const baseUrl = "https://bbazar.com.bd/"
// export const baseUrl = "https://demo.versionaization.com/laravel/BBazar/public/"
export const appSubDir = "/"
export const apiBaseUrl = baseUrl+"api/"
export const storageBaseUrl = baseUrl+"storage/"
export const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY ? process.env.REACT_APP_RECAPTCHA_SITEKEY : ""
// Pricing in £
export const perMiles = 1.75
export const perMin = 1.38

export const apiConfig = {
    baseUrl: baseUrl,
    messengerBaseUrl: apiBaseUrl + "messenger/",
    loginUrl: apiBaseUrl + "v2/login",
    loginWithFBUrl: apiBaseUrl + "login-with-fb",
    logOut: apiBaseUrl + "logout",
    register: apiBaseUrl + "register",
    sendOtp: apiBaseUrl + "send-otp",
    userDetails: apiBaseUrl + "user-details",
    editUserDetails: apiBaseUrl + "profile/edit/details",
    placeOrder: apiBaseUrl + "v2/place-order",
    slides: apiBaseUrl + "slides",
    products: apiBaseUrl + `products/random`,
    privateProducts: apiBaseUrl + `private/products/random`,
    brands: apiBaseUrl + "brands",
    wishlist: apiBaseUrl + "wishlist",
    orders: apiBaseUrl + "v2/orders",
    transactions: apiBaseUrl + "transactions",
    links: apiBaseUrl + "fb-group-links",
    categoriesAndSubCategories: apiBaseUrl + "products/categories-and-subcategories",
    homeScreen: {
        public: apiBaseUrl + "app-data/home-screen",
        private: apiBaseUrl + "private-app-data/home-screen"
    }
}
export const productImageUrl = baseUrl
export const profileImageUrl = baseUrl + "images/profile/"
