import { IonCol, IonRow } from '@ionic/react';
import './Footer.scss'
import { faRefresh, faStar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GooglePlaystoreIcon from './icons/GooglePlaystoreIcon';
import BbazarResellerAppQR from './icons/BbazarResellerAppQR';
import BbazarUserAppQR from './icons/BbazarUserAppQR';

const Footer: React.FC = () => {

    return <>
        <footer>
            <ul>
                <li>
                    <FontAwesomeIcon size='5x' icon={faStar} />
                    <div className='body'>
                        <h4>QUALITY ASSURED</h4>
                        <p>Get High Quality Products</p>
                    </div>
                </li>
                <li>
                    <FontAwesomeIcon size='5x' icon={faTruck} />
                    <div className='body'>
                        <h4>DELIVERY</h4>
                        <p>All over Bangladesh</p>
                    </div>
                </li>
                <li>
                    <FontAwesomeIcon size='5x' icon={faRefresh} />
                    <div className='body'>
                        <h4>HAPPY RETURN</h4>
                        <p>7 days return facility</p>
                    </div>
                </li>
            </ul>
            <div className='btn-install-group'>
                <a href='https://play.google.com/store/apps/details?id=bbazar.app.new' target='_blank' className='btn-install'>
                    <GooglePlaystoreIcon />
                    <div className='text'>
                        <h2>Scan Or Click To Install</h2>
                        <h1>Bbazar Reseller App</h1>
                    </div>
                    <BbazarResellerAppQR />
                </a>
                <a href='https://play.google.com/store/apps/details?id=bbazar.app' target='_blank' className='btn-install'>
                    <GooglePlaystoreIcon />
                    <div className='text'>
                        <h2>Scan Or Click To Install</h2>
                        <h1>Bbazar User App</h1>
                    </div>
                    <BbazarUserAppQR />
                </a>
            </div>
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <div className="about">
                        <h1>Bbazar</h1>
                        <hr />
                        <h3>A Big Online Market Place and Reseller Platform in Bangladesh</h3>
                        <p>বিবাজার বাংলাদেশের একটি বড় অনলাইন মার্কেট প্লেস এবং রিসেলার প্ল্যাটফর্ম। এখানে ১০,০০০ + পণ্যের পাইকারি ক্রয়ের সুবিধা রয়েছে। বিবাজার, বাংলাদেশের প্রথম অনলাইন পাইকারি প্ল্যাটফর্ম, যারা পাইকারি দামে পন্য বিক্রি করে।</p>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <div className='payment-banner'>
                        <img src="assets/img/sslcommerz.webp" alt='Brand Logo' />
                    </div>
                    <div className='info'>
                        <div className='links'>
                            <a href="#">Terms & Conditions</a><a href="#">Return Policy</a><a href="#">Refund Policy</a><a href="#">Privacy Policy</a>
                        </div>
                        <div className='copyright'>
                            Bbazar &copy; 2014-{new Date().getFullYear()}, All Rights Reserved.
                        </div>
                    </div>
                </IonCol>
            </IonRow>
        </footer>
    </>
};

export default Footer;
