import { IonButton, IonIcon, IonInput, IonItem, IonSpinner, IonText } from '@ionic/react';
import { useRef, useState } from 'react';
import './SearchBar.scss'
import { SimpleProduct } from '../../types/product.type';
import ProductListItem from '../Products/ProductListItem';
import { fetchProducts, getProducts } from '../../services/products/search-products.service';
import { search } from 'ionicons/icons';
import { appSubDir } from '../../configs/app';

const SearchBar: React.FC = () => {
    const input = useRef<HTMLIonInputElement>(null)
    const [products, setProducts] = useState<SimpleProduct[] | null>(null)
    const [loading, setLoading] = useState(false)
    const nothingFound = products?.length === 0 && !loading;
    const searchRoute = appSubDir + (input.current?.value ? "search/" + encodeURIComponent(input.current?.value) : "")
    function onChange() {
        if (input.current && input.current.value && typeof input.current.value == 'string') {
            const localRes = getProducts(input.current.value)
            if (localRes && localRes.data) {
                setProducts(localRes.data)
            } else {
                setLoading(true)
            }
            fetchProducts(input.current.value).then((res) => {
                if (res && res.data) {
                    setProducts(res.data)
                } else {
                    setProducts(null)
                }
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setProducts(null)
        }
    }

    return <>
        <div id='search-bar-container'>
            <IonItem className="search-bar">
                <IonIcon icon={search} slot='start' />
                <IonInput ref={input} clearInput={true} debounce={500} onIonChange={onChange} placeholder='I am looking for...' />
                {!loading && products && <IonButton routerLink={searchRoute} slot='end' fill='clear' color="primary">See All</IonButton>}
            </IonItem>
            <div className='result'>
                {loading && <div className='spinner'><IonSpinner /></div>}
                {!loading && products && products.map((product, index) => <ProductListItem product={product} key={index} />)}
                {nothingFound && <div className="nothing-found">
                    <IonText color="medium">Nothing Found</IonText>
                </div>}
            </div>
        </div>
    </>
};

export default SearchBar;
