import { IonCol, IonContent, IonRow, IonSpinner, IonText } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { appSubDir, baseUrl } from '../../configs/app';
import Pagination from '../../components/Pagination';
import { fetchProduct, getLocalProduct } from '../../services/products/product.service';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import ZoomableImg from '../../components/ZoomableImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import HtmlParser from '../../components/HtmlParser';
import './Product.scss'
import CategoryProducts from '../../components/Products/CategoryProducts';
import SubCategoryProductsGrid from '../../components/Products/SubCategoryProductsGrid';
import QuickViewAddToCart from '../../components/Products/QuickViewAddToCart';
import NewArrivalComponent from '../../components/ProductDetails/NewArrivalComponent';
import FlashSaleComponent from '../../components/ProductDetails/FlashSaleComponent';
import BestSellingComponent from '../../components/ProductDetails/BestSellingComponent';
type CustomParam = {
    ID: string
}
const Product: React.FC = () => {
    const { ID } = useParams() as CustomParam
    const contentRef = useRef<HTMLIonContentElement>(null);
    const localProduct = getLocalProduct(parseInt(ID))
    const [item, setItem] = useState(localProduct)
    const [loading, setLoading] = useState(!localProduct)
    const BDT = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'BDT'
    });
    const scrollToTop = () => {
        contentRef.current?.scrollToPoint(0, 0, 1000);
    };
    useEffect(() => {
        if (!localProduct) {
            setLoading(true)
        }
        fetchProduct(parseInt(ID)).then(product => {
            if (product) {
                setItem(product)
            }
        }).finally(() => {
            setLoading(false)
        })
        scrollToTop()
    }, [ID])
    function getImages() {
        if (item) {
            let images = [baseUrl + item.image] as string[]
            if (!!item.gallery_1) {
                images.push(baseUrl + item.gallery_1)
            }
            if (!!item.gallery_2) {
                images.push(baseUrl + item.gallery_2)
            }
            return images

        } else {
            return [] as string[]
        }
    }
    return (
        <IonContent ref={contentRef} fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
                    <div className='page-container'>
                        {loading && <div className='box-center'><IonSpinner /></div>}
                        {!loading && !item && <div className='box-center'><IonText><h1>Something Went Wrong</h1></IonText></div>}
                        {!loading && item && <div id="single-product">
                            <div className='row'>
                                <div className='col gallary'>
                                    <Swiper
                                        autoplay={{
                                            delay: 3000
                                        }}
                                        zoom={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={true}
                                        modules={[Autoplay, Pagination, Navigation]}>
                                        {getImages().map((url, index) => <SwiperSlide key={index}>
                                            <ZoomableImg src={url} />
                                        </SwiperSlide>)}
                                    </Swiper>
                                </div>
                                <div className='col meta'>
                                    <h1 className='title'>{item.name}</h1>
                                    <div className='pricing'>
                                        <IonText className='sale' color='primary'>{BDT.format(item.sale_price)}</IonText>
                                        <IonText className='regular'>{BDT.format(item.regular_price)}</IonText>
                                    </div>
                                    {item.quantity > 0 ?
                                        <IonText className='stock'>Only <b>{item.quantity}</b> Item{item.quantity > 1 ? "s" : ''} left</IonText> :
                                        <IonText className='stock' color='danger'>Out Of Stock</IonText>}
                                    <div className='quantity'>
                                        <QuickViewAddToCart item={item} />
                                    </div>
                                    <div className='cat'>
                                        <h6>Categories</h6>
                                        {item.category_id > 0 && <Link to={appSubDir + "categories/" + item.category_id}>{item.category_title}</Link>}
                                        {item.subcategory_id > 0 && <FontAwesomeIcon icon={faAngleRight} />}
                                        {item.subcategory_id > 0 && <Link to={appSubDir + "categories/" + item.category_id + "/sub-categories/" + item.subcategory_id}>{item.sub_category_title}</Link>}
                                    </div>
                                    <div className='cat'>
                                        <h6>Brand</h6>
                                        {item.brand_id > 0 && <Link to={appSubDir + "brands/" + item.brand_id}>{item.brand_name}</Link>}
                                    </div>
                                </div>
                                {window.screen.width > 1024 && <div className='col category'>
                                    <CategoryProducts catId={item.category_id} excludedProductId={item.id} title={item.category_title} />
                                </div>}
                            </div>
                            <div className='description'>
                                <h6>Description</h6>
                                <HtmlParser html={item.description} />
                            </div>
                            {window.screen.width <= 1024 && <div className='category'>
                                <CategoryProducts catId={item.category_id} excludedProductId={item.id} title={item.category_title} grid={true} />
                            </div>}
                        </div>}
                    </div>
                    {!loading && item && <SubCategoryProductsGrid product={item} title={item.sub_category_title} />}
                    <FlashSaleComponent />
                    <NewArrivalComponent />
                    <BestSellingComponent />
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Product;
