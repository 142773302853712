import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Product from '../../types/product.type';
import ProductsGrid from './ProductsGrid';
import { fetchSubCategoryProducts, getSubCategoryProducts } from '../../services/products/sub-category-products.service';
type Props = {
    product: Product
    title: string
}
const SubCategoryProductsGrid: React.FC<Props> = ({ product, title }) => {
    const [items, setItems] = useState(getSubCategoryProducts(product.subcategory_id)?.data)
    function fetchData() {
        fetchSubCategoryProducts(product.subcategory_id)
            .then(res => {
                if (res) {
                    setItems(res.data.filter((items2,index)=>items2.id != product.id))
                }
            })
    }
    useEffect(() => {
        fetchData()
    }, [])
    if (!items || items.length < 1) {
        return null
    }
    return (
        <ProductsGrid title={title} products={items} routerLink={appSubDir + "categories/" + product.category_id + "/sub-categories/" + product.subcategory_id} />
    );
};

export default SubCategoryProductsGrid;
