import { useEffect, useState } from 'react';
import { apiBaseUrl, appSubDir } from '../../configs/app';
import { SimpleProduct } from '../../types/product.type';
import ProductsVerticalBox from '../Products/ProductsVerticalBox';
import { HomeItem } from '../../types/home.type';
import { Link } from 'react-router-dom';


type Props = {
    item: HomeItem
    children: JSX.Element,
}
const HomeItemLink: React.FC<Props> = ({ item, children }) => {
    return <>
        {item.category_id && !item.sub_category_id && <Link to={appSubDir + 'categories/' + item.category_id}>
            {children}
        </Link>}
        {item.category_id && item.sub_category_id && <Link to={appSubDir + "categories/" + item.category_id + "/sub-categories/" + item.sub_category_id}>
            {children}
        </Link>}
        {!item.category_id && !item.sub_category_id && item.url && <a href={item.url} target='_blank'>
            {children}
        </a>}
    </>
};

export default HomeItemLink;
